import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import MeetOurTeamPage from "./pages/MeetOurTeamPage/MeetOurTeamPage";
import InventoriesPage from "./pages/InventoriesPage/InventoriesPage";
import SingleInventoryPage from "./pages/SingleInventoryPage/SingleInventoryPage";
import Chat from "./components/Chat/Chat";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-number-input/style.css";
import PwSingleInventoryPage from "./pages/PwSingleInventoryPage/PwSingleInventoryPage";
import { useContext, useEffect } from "react";
import { agencyContext } from "./context/agencyContext";
import { getAgencyDetailsApi } from "./redux/api/Agency";
import { Spin } from "antd";
function App() {
  const getAgencyDetails = useSelector((state) => state.getAgencyDetails);
  const dispatch = useDispatch();
  const agencyId = useContext(agencyContext);
  useEffect(() => {
    console.log = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error = () => {};
    console.debug = () => {};
    if (agencyId) {
      getAgencyDetailsApi(dispatch, agencyId);
    }
  }, [agencyId]);

  return (
    <div className="agency-catalogue">
      {getAgencyDetails.data === null ? (
        <div
          style={{
            height: "100vh",
            marginTop: "5%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/meet-our-team" element={<MeetOurTeamPage />} />
          <Route
            exact
            path="/inventories/:inventoryId"
            element={<SingleInventoryPage />}
          />
          <Route
            exact
            path="/pw-inventories/:projectId"
            element={<PwSingleInventoryPage />}
          />
          <Route exact path="/inventories" element={<InventoriesPage />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
