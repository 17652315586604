import { combineReducers, configureStore } from "@reduxjs/toolkit";
import getAnnouncementDetailSlice from "./slice/Announcements/getAnnouncementDetailSlice";
import getTeamDetailSlice from "./slice/MeetOurTeam/getTeamDetailSlice";
import getMeetingListSlice from "./slice/Appointments/getMeetingList";
import bookMeetingSlice from "./slice/Appointments/bookMeetingSlice";
import getInventoriesDetailsSlice from "./slice/Inventories/getInventoriesDetailsSlice";
import getInventoryDetailsForPublicSlice from "./slice/Inventories/getInventoryDetailsForPublicSlice";
import chatSlice from "./slice/Chat/chatSlice";
import initiateChatSlice from "./slice/Chat/initiateChatSlice";
import sendChatMessageSlice from "./slice/Chat/sendChatMessageSlice";
import getUserMessagesSlice from "./slice/Chat/getUserMessagesSlice";
import createReviewForAgencySlice from "./slice/Testinomials/createReviewForAgencySlice";
import getReviewForAgencyByIdSlice from "./slice/Testinomials/getReviewForAgencyByIdSlice";
import createReviewSlice from "./slice/MeetOurTeam/createReviewSlice";
import iamInterestedSlice from "./slice/Inventories/iamInterested";
import getAgencyDetailsSlice from "./slice/Agency/getAgencyDetailsSlice";
import analyticClickSlice from "./slice/Analytic/analyticClickSlice";
import analyticViewSlice from "./slice/Analytic/analyticViewSlice";
import getUsersToChatSlice from "./slice/Chat/getUsersToChatSlice";
import isAgencyWithinRadiusSlice from "./slice/Inventories/isAgencyWithinRadiusSlice";
import getProjectTypesSlice from "./slice/Inventories/getProjectTypesSlice";
import getProjectSubTypesSlice from "./slice/Inventories/getProjectSubTypesSlice";
import getLandAreaSlice from "./slice/Inventories/getLandAreaSlice";
import addLeadFormSlice from "./slice/Agency/addLeadFormSlice";
const rootSlices = combineReducers({
  getTeamDetail: getTeamDetailSlice,
  getAnnouncementDetail: getAnnouncementDetailSlice,
  getMeetingList: getMeetingListSlice,
  bookMeeting: bookMeetingSlice,
  getInventoriesDetail: getInventoriesDetailsSlice,
  getInventoryDetailsForPublic: getInventoryDetailsForPublicSlice,
  chat: chatSlice,
  initiateChat: initiateChatSlice,
  sendChatMessage: sendChatMessageSlice,
  getUserMessages: getUserMessagesSlice,
  iamInterested: iamInterestedSlice,
  createReviewForAgency: createReviewForAgencySlice,
  getReviewForAgencyById: getReviewForAgencyByIdSlice,
  createReview: createReviewSlice,
  getAgencyDetails: getAgencyDetailsSlice,
  analyticClick: analyticClickSlice,
  analyticView: analyticViewSlice,
  getUsersToChat: getUsersToChatSlice,
  isAgencyWithinRadius: isAgencyWithinRadiusSlice,
  getProjectTypes: getProjectTypesSlice,
  getProjectSubTypes: getProjectSubTypesSlice,
  getLandArea: getLandAreaSlice,
  addLeadForm: addLeadFormSlice,
});

const store = configureStore({
  middleware: (serialData) =>
    serialData({
      serializableCheck: false,
    }),
  // non serial data issue fixed
  reducer: rootSlices,
});
export const resetState = () => {
  return rootSlices(undefined, {});
};

export default store;
