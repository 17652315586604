import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Chat from "../Chat/Chat";
import { clearChatData } from "../../redux/slice/Chat/chatSlice";

const CatalogueWrappar = ({ children }) => {
  const dispatch = useDispatch();

  const chat = useSelector((state) => state.chat);

  useEffect(() => {
    return () => {
      dispatch(clearChatData());
    };
  }, []);
  return (
    <>
      {children}
      {chat.available && <Chat />}
    </>
  );
};

export default CatalogueWrappar;
