export const API = {
  announcements: {
    getAnnouncementDetail: `/v1/catalogue/getAnnouncementDetail`,
  },
  meetOurTeam: {
    getTeamDetail: `/v1/catalogue/getTeamDetail`,
    getMeetingList: `/v1/public-meeting/get-public-calendar/slot`,
    bookMeeting: `/v1/public-meeting/public-calendar/slot-booking`,
    createReview: `/v1/rate-and-review/createReviewForAgent`,
  },
  inventories: {
    getInventoriesDetails: `/v1/agency/getlistingForPublic`,
    getAllPWProjectList: `/v1/property-wallet-project/getAllProjectList/Forpublic`,
    getCatalogueDetailByAgencyId: `/v1/catalogue/getCatalogueDetailByAgencyId`,
    getInventoryDetailsForPublic: `/v1/inventory/getInventoryDetailsForPublic`,
    getProjectByIdpublic: `/v1/property-wallet-project/getProjectByIdpublic`,
    addPublicLead: `/v1/lead/add-public-lead`,
    addPublicPWLead: `/v1/lead/add-new-lead-forPublic`,
    isAgencyWithinRadius: `/v1/dashboard/isAgencyWithinRadius`,
    getProjectTypes: `/v1/project-type`,
    getProjectSubTypes: `/v1/project-sub-type/getList`,
    getLandArea: `/v1/land-area`,
  },
  chat: {
    initiateChat: `/v1/public-chat/start-chat`,
    sendChatMessage: `/v1/public-chat/send-message/to-agent`,
    getUserMessages: `/v1/public-chat/getAllChats/ForPublicUser`,
  },
  testinomials: {
    createReviewForAgency: `/v1/rate-and-review/createReviewForAgency`,
    getReviewForAgencyById: `/v1/rate-and-review/getReviewForAgencyById`,
  },
  agency: {
    getAgencyDetails: `/v1/catalogue/getAgencyDetailByAgencyId`,
    addLeadForm: `/v1/lead/add-new-lead-forCatalogue`,
  },
  analytic: {
    analyticClick: `/v1/analytic/analyticClick`,
    analyticView: `/v1/analytic/analyticView`,
  },
  packages: {
    packagesList: `/v1/pwpackages/public/getAll`,
    discount: `/v1/pwpackages/purchase/public/discount`,
    checkout: `/v1/voucher-redeem/purchase`,
    checkoutwithpaymob: `/v1/voucher-redeem/purchase/by-paymob`,
    getAllCustomPackages: `/v1/pwpackages/getAllCustomPackages/forWebsite`,
    createPackage: `/v1/pwpackages/createCustomPackage`,
  },
};
