import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import axios from "axios";
import App from "./App";
import store from "./redux/store";
import "./index.css";
import { agencyContext } from "./context/agencyContext";

axios.defaults.baseURL = "https://productionbackend.propertywallet.pk";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <agencyContext.Provider value={8788}>
        <App />
      </agencyContext.Provider>
    </Provider>
  </BrowserRouter>
);
